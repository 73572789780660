import React from "react"
import { Page } from "../templates/page"

const TermsOfUsePage: React.FC = () => {
  return (
    <Page title={"Terms of Use"} description={"MaxRewards terms of use page"}>
      <section className="uk-section uk-section-muted">
        <div className="uk-container uk-container-small">
          <div className="uk-card uk-card-default uk-card-body">
            <p className="uk-text-large primary">
              TERMS OF USE FOR MAXREWARDS MOBILE APPLICATION
            </p>
            <p>
              The MaxRewards mobile application (the &ldquo;<strong>App</strong>
              &rdquo;) is a copyrighted work belonging to MaxRewards, Inc.&nbsp;
              (&ldquo;<strong>Company</strong>&rdquo;, &ldquo;
              <strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;,
              and &ldquo;<strong>we</strong>&rdquo;).&nbsp; Certain features of
              the App may be subject to additional guidelines, terms, or rules,
              which will be posted on the App in connection with such
              features.&nbsp; All such additional terms, guidelines, and rules
              are incorporated by reference into these Terms.
            </p>
            <p>
              These Terms of Use (these &ldquo;<strong>Terms</strong>&rdquo;)
              set forth the legally binding terms and conditions that govern the
              use of the App.&nbsp; By downloading, installing, or using the
              App, or by clicking the &ldquo;I agree&rdquo; or similar icon, the
              user of the App (&ldquo;<strong>you</strong>&rdquo; or &ldquo;
              <strong>your</strong>&rdquo;) acknowledges and agree that you have
              read, understood, and agree to be bound by these Terms and the
              Privacy Policy, located at maxrewards.com (on behalf of yourself
              or the entity that you represent), and you represent and warrant
              that you have the right, authority, and capacity to enter into
              these Terms (on behalf of yourself or the entity that you
              represent).&nbsp; You may not access or use the App or accept the
              Terms if you are not at least 18 years old.&nbsp; If you do not
              agree to the Terms, you should not install or use the App or you
              should stop using the App and uninstall it.
            </p>
            <p>
              This Agreement requires the use of arbitration (Section 17) on an
              individual basis to resolve disputes, rather than jury trials or
              class actions, and also limit the remedies available to you in the
              event of a dispute.
            </p>
            <p>
              We reserve the right to update and modify these Terms at any time,
              so please review them frequently either via the App or by visiting
              maxrewards.com.&nbsp; Your continued use of the App will signify
              your acceptance of any changes to these Terms.
            </p>
            <ol>
              <li>
                <strong>Access to the App</strong>.
              </li>
              <ol>
                <li>
                  <u>License</u>.&nbsp; Subject to these Terms, the Company
                  grants you a non-transferable, non-exclusive, non-assignable,
                  non-sublicensable, revocable limited license to use and access
                  the App on any mobile device (&ldquo;<strong>Device</strong>
                  &rdquo;) that you own or control.&nbsp; Without limiting the
                  generality of the foregoing, any access to the App by
                  automated inquiry devices, robots, or repetitive data
                  gathering and extraction tools, routines, scripts, or other
                  mechanisms with similar functionality, is expressly
                  prohibited.
                </li>
                <li>
                  <u>Certain Restrictions</u>.&nbsp; The rights granted to you
                  in these Terms are subject to the following restrictions: you
                  may not: (a) sublicense, sell, rent, lease, transfer, assign,
                  distribute, host, or otherwise commercially exploit or
                  monetize the App, whether in whole or in part, or any content
                  displayed on the App, or otherwise make the App available to
                  any third party without our permission; (b) modify, make
                  derivative works of, disassemble, reverse compile or reverse
                  engineer any part of the App; (c) access the App in order to
                  build a similar or competitive mobile application, website,
                  product, or service; or (d) except as expressly stated herein,
                  copy, reproduce, distribute, republish, download, display,
                  post or transmit in any form or by any means the App or any
                  portion of the App.&nbsp; Unless otherwise indicated, any
                  future release, update, or other addition to functionality of
                  the App shall be subject to these Terms.&nbsp; All copyright
                  and other proprietary notices on the App (or on any content
                  displayed on the App) must be retained on all copies thereof.
                </li>
                <li>
                  <u>Modification</u>.&nbsp; We reserve the right, at any time,
                  to modify, suspend, or discontinue the App (in whole or in
                  part) with or without notice to you.&nbsp; You agree that we
                  will not be liable to you or to any third party for any
                  modification, suspension, or discontinuation of the App or any
                  part thereof.
                </li>
                <li>
                  <u>No Support or Maintenance</u>.&nbsp; You acknowledge and
                  agree that we have no obligation to provide you with any
                  support or maintenance in connection with the App.
                </li>
                <li>
                  <u>Ownership</u>.&nbsp; Excluding any Materials that you may
                  provide (defined below), you acknowledge that all the
                  intellectual property rights, including copyrights, patents,
                  trademarks, and trade secrets, in the App and its content are
                  owned by us or by our suppliers.&nbsp; Neither these Terms
                  (nor your access to the App) transfers to you or any third
                  party any rights, title or interest in or to such intellectual
                  property rights, except for the limited access rights
                  expressly set forth in Section 1.1.&nbsp; We and our suppliers
                  reserve all rights not granted in these Terms.&nbsp; There are
                  no implied licenses granted under these Terms.
                </li>
              </ol>
              <li>
                <strong>Accounts</strong>.
              </li>
              <ol>
                <li>
                  <u>Account Creation</u>.&nbsp; In order to use certain
                  features of the App, you must register for an account (&ldquo;
                  <strong>Account</strong>&rdquo;) and provide certain
                  information about yourself as prompted by the account
                  registration form.&nbsp; You represent and warrant that: (a)
                  all required registration information you submit is truthful
                  and accurate; (b) you will maintain the accuracy of such
                  information.&nbsp; You may terminate your Account at any time,
                  for any reason, by following the instructions on the
                  App.&nbsp; We may also suspend or terminate your Account in
                  accordance with these Terms.
                </li>
                <li>
                  <u>Subscriptions</u>.&nbsp; It is free to register for an
                  Account, however we also may offer additional premium
                  subscriptions for you to purchase (a &ldquo;
                  <strong>Subscription</strong>&rdquo;).&nbsp; Details on the
                  costs and the specific services provided with a particular
                  Subscription will be found on the registration page for that
                  Subscription.
                </li>
                <ol>
                  <li>
                    We reserve the right to add or remove features or services
                    from a particular Subscription, to add new premium
                    Subscriptions or to stop providing a premium Subscription at
                    any time in our sole discretion.&nbsp; We will provide you
                    notice if we do so.
                  </li>
                  <li>
                    So long as you maintain an active Subscription, we will
                    continue to honor your then-current pricing for that
                    Subscription.&nbsp; However, if your Subscription ends for
                    any reason, we will no longer honor the original pricing for
                    that Subscription if you try to reinstate it or enter into a
                    new Subscription, although we reserve the right to (but are
                    not obligated to) evaluate the circumstances of the
                    termination or cancellation of your Subscription and
                    determine if we will honor the original pricing.&nbsp;
                    Subject to the foregoing, we may change the prices we charge
                    for Subscriptions from time to time, and will communicate
                    any price changes to you in advance.&nbsp; To the extent any
                    such price changes apply to your Subscription(s), those
                    changes will take effect at the start of the next billing
                    cycle for your Subscription following the date of the price
                    change.&nbsp; As permitted by local law, you accept any
                    applicable new price by continuing to use the App after the
                    price change takes effect.&nbsp; If you do not agree with
                    any price change that is applicable to you, you have the
                    right to reject the change by canceling your Subscription
                    prior to the price change going into effect.
                  </li>
                  <li>
                    Subscriptions are automatically renewing and unless you
                    cancel it, your Subscription will renew for the same time
                    period at the same pricing as the current Subscription
                    period, subject to the provisions of Section 2.2.2
                    above.&nbsp; By purchasing a Subscription, you authorize us
                    to charge you, and you agree to pay, on a recurring basis
                    (e.g., monthly or yearly) for the applicable Subscription
                    charge, any and all taxes or possible transaction fees, and
                    any other charges incurred in connection with your use of
                    the Subscription.&nbsp; To avoid charges for a new
                    Subscription period, you must cancel the Subscription before
                    the end of the then-current Subscription period in
                    accordance with the provisions of Section 9 below or the
                    instructions set forth in the App (if any).&nbsp; If you
                    cancel your Subscription, you may use your Subscription
                    until the end of the period you last paid for, but you will
                    not be eligible for a prorated refund and your Subscription
                    will not be renewed when that period expires.
                  </li>
                </ol>
                <li>
                  <u>Account Responsibilities</u>.&nbsp; You are responsible for
                  maintaining the confidentiality of your Account login
                  information and access credentials (your &ldquo;
                  <strong>Access Credentials</strong>&rdquo;) and are fully
                  responsible for all activities that occur under your
                  Account.&nbsp; Anyone with knowledge of, or access to, your
                  Access Credentials or the security questions and responses
                  associated with your Account can use that information to gain
                  access to your Account.&nbsp; You agree to immediately notify
                  us of any unauthorized use, or suspected unauthorized use of
                  your Account or any other breach of security.&nbsp; You are
                  solely liable for any claims, damages, losses, costs or other
                  liabilities resulting from or caused by any failure to keep
                  your Access Credentials and responses confidential, whether
                  such failure occurs with or without your knowledge or
                  consent.&nbsp; We cannot and will not be liable for any loss
                  or damage arising from your failure to comply with the above
                  requirements.&nbsp; We reserve the right to take any action
                  that we deem necessary to ensure the security of the App and
                  your Account, including without limitation changing your
                  password, terminating your Account, or requesting additional
                  information to authorize activity on your Account.
                </li>
                <li>
                  <u>Security Violations</u>.&nbsp; You are responsible for
                  ensuring and maintaining security of your systems and the
                  devices that connect to and use the App, including
                  implementation of necessary patches and operating system
                  updates.&nbsp; You are not allowed to use the App to interfere
                  with, gain unauthorized access to, or otherwise violate the
                  security of our (or another party's) server, network, network
                  access, personal computer or mobile devices, software or data,
                  or other system.
                </li>
              </ol>
              <li>
                <strong>Materials and Feedback</strong>.
              </li>
              <ol>
                <li>
                  <u>Your Materials</u>.&nbsp; Any data, text, graphics,
                  photographs and their selection and arrangement, and any other
                  materials uploaded to the Service by you are your
                  &ldquo;Materials.&rdquo; You represent and warrant that your
                  Materials are original to you and that you exclusively own the
                  rights to such Materials, including the right to grant all of
                  the rights and licenses in these Terms without the Company
                  incurring any third-party obligations or liability arising out
                  of its exercise of such rights and licenses.&nbsp; All of your
                  Materials are your sole responsibility, and the Company is not
                  responsible for any material that you upload, post, or
                  otherwise make available.&nbsp; By uploading, distributing,
                  transmitting or otherwise using our Materials with the
                  Service, you grant to us a perpetual, nonexclusive,
                  transferable, royalty-free, sublicensable, and worldwide
                  license to use, host, reproduce, modify, adapt, publish,
                  translate, create derivative works from, distribute, perform,
                  and display Your Materials in connection with operating and
                  providing the App and any services we provide, subject to the
                  terms of the Privacy Policy.&nbsp; The Company does not
                  guarantee the accuracy, quality, or integrity of any user
                  Materials posted by you or any other user of the App and you
                  agree that the Company will not under any circumstances be
                  liable for any user Materials, including, but not limited to,
                  errors in any user Materials, or any loss or damage incurred
                  by use of user Materials.&nbsp; The Company reserves the right
                  to remove and permanently delete your Materials from the App
                  with or without notice for any reason or no reason.
                </li>
                <li>
                  <u>Feedback</u>.&nbsp; If you provide the Company with any
                  feedback or suggestions regarding the App (&ldquo;
                  <strong>Feedback</strong>&rdquo;), you hereby assign to the
                  Company all rights in such Feedback and agree that the Company
                  shall have the right to use and fully exploit such Feedback
                  and related information in any manner it deems
                  appropriate.&nbsp; The Company will treat any Feedback you
                  provide to the Company as non-confidential and
                  non-proprietary.&nbsp; You agree that you will not submit to
                  the Company any information or ideas that you consider to be
                  confidential or proprietary.
                </li>
              </ol>
              <li>
                <strong>Acceptable Use Policy</strong>.&nbsp; The following
                terms constitute our &ldquo;
                <strong>Acceptable Use Policy</strong>&rdquo;:
              </li>
              <ol>
                <li>
                  You agree not to use the App to collect, upload, transmit,
                  display, or distribute any Materials (i) that violates any
                  third-party right, including any copyright, trademark, patent,
                  trade secret, moral right, privacy right, right of publicity,
                  or any other intellectual property or proprietary right; (ii)
                  that is unlawful, harassing, abusive, tortious, threatening,
                  harmful, invasive of another&rsquo;s privacy, vulgar,
                  defamatory, false, intentionally misleading, trade libelous,
                  pornographic, obscene, patently offensive, promotes racism,
                  bigotry, hatred, or physical harm of any kind against any
                  group or individual or is otherwise objectionable; (iii) that
                  is harmful to minors in any way; or (iv) that is in violation
                  of any law, regulation, or obligations or restrictions imposed
                  by any third party.
                </li>
                <li>
                  In addition, you agree not to: (i) upload, transmit, or
                  distribute to or through the App any computer viruses, worms,
                  or any software intended to damage or alter a computer system
                  or data; (ii) send through the App unsolicited or unauthorized
                  advertising, promotional materials, junk mail, spam, chain
                  letters, pyramid schemes, or any other form of duplicative or
                  unsolicited messages, whether commercial or otherwise; (iii)
                  use the App to harvest, collect, gather or assemble
                  information or data regarding other users, including e-mail
                  addresses, without their consent; (iv) interfere with,
                  disrupt, or create an undue burden on servers or networks
                  connected to the App, or violate the regulations, policies or
                  procedures of such networks; (v) attempt to gain unauthorized
                  access to the App (or to other computer systems or networks
                  connected to or used together with the App), whether through
                  password mining or any other means; (vi) harass or interfere
                  with any other user&rsquo;s use and enjoyment of the App;
                  (vii) use software or automated agents or scripts to produce
                  multiple accounts on the App, or to generate automated
                  searches, requests, or queries to (or to strip, scrape, or
                  mine data from) the App (provided, however, that we
                  conditionally grant to the operators of public search engines
                  revocable permission to use spiders to copy materials from the
                  App for the sole purpose of and solely to the extent necessary
                  for creating publicly available searchable indices of the
                  materials, but not caches or archives of such materials,
                  subject to the parameters set forth in our robots.txt file);
                  or (viii) use the App for any illegal, unauthorized,
                  unintended, unsafe, hazardous, fraudulent, deceptive or
                  unlawful purposes (including, without limitation, in violation
                  of any data, privacy or export control laws), or in any manner
                  inconsistent with these Terms.
                </li>
              </ol>
              <li>
                <strong>Third-Party Links &amp; Ads</strong>.
                <strong>&nbsp; </strong>The App may contain links to third-party
                websites and services, and/or display advertisements for third
                parties (collectively, &ldquo;
                <strong>Third-Party Links &amp; Ads</strong>&rdquo;).&nbsp; Such
                Third-Party Links &amp; Ads are not under the control of the
                Company, and the Company is not responsible for any Third-Party
                Links &amp; Ads.&nbsp; The Company provides access to these
                Third-Party Links &amp; Ads only as a convenience to you, and
                does not review, approve, monitor, endorse, warrant, or make any
                representations with respect to Third-Party Links &amp;
                Ads.&nbsp; You use all Third-Party Links &amp; Ads at your own
                risk and should apply a suitable level of caution and discretion
                in doing so.&nbsp; When you click on any of the Third-Party
                Links &amp; Ads, the applicable third party&rsquo;s terms and
                policies apply, including the third party&rsquo;s privacy and
                data gathering practices.&nbsp; You should make whatever
                investigation you feel necessary or appropriate before
                proceeding with any transaction in connection with such
                Third-Party Links &amp; Ads.
              </li>
              <li>
                <strong>Disclaimers</strong>.&nbsp; Neither we, our service
                providers, nor any third-party beneficiary makes ANY WARRANTIES,
                EXPRESS OR IMPLIED, about the App (including the content or
                other information delivered to you as part of the App).&nbsp; In
                addition, we do not promise uninterrupted or problem-free
                service and do not promise that the data or information provided
                to you will be error free.&nbsp; Use of the App is at your own
                sole risk.&nbsp; Certain functions of the App may violate your
                terms of use with third parties (including, but not limited to
                your credit card provider).&nbsp; By continuing to use the App
                you acknowledge and accept these risks and any other risks
                associated with your use of the App.&nbsp; THE APP IS PROVIDED
                ON AN &ldquo;AS-IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS,
                AND THE COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND
                ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
                ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.&nbsp; NEITHER WE, NOR
                ANY OF OUR SUPPLIERS, MAKE ANY WARRANTIES, EXPRESS OR IMPLIED,
                ABOUT THE APP OR ABOUT ANY DATA OR INFORMATION OR APP OR RELATED
                SERVICES PROVIDED TO YOU.&nbsp; WE (AND OUR SUPPLIERS) MAKE NO
                WARRANTY THAT THE APP WILL MEET YOUR REQUIREMENTS, WILL BE
                AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
                BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER
                HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.&nbsp; IF APPLICABLE LAW
                REQUIRES ANY WARRANTIES WITH RESPECT TO THE APP, ALL SUCH
                WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE
                DATE OF FIRST USE.&nbsp; SOME JURISDICTIONS DO NOT ALLOW THE
                EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
                APPLY TO YOU.&nbsp; SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS
                ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION
                MAY NOT APPLY TO YOU.
              </li>
              <li>
                <strong>Indemnification; Release</strong>.
              </li>
              <ol>
                <li>
                  <u>Indemnification</u>.&nbsp; You agree to indemnify and hold
                  the Company (and its officers, employees, and agents)
                  harmless, including costs and attorneys&rsquo; fees, from any
                  claim or demand made by any third party due to or in any way
                  arising out of (a) your use of the App, (b) your violation of
                  these Terms, (c) your violation of applicable laws or
                  regulations or (d) your Materials.&nbsp; The Company reserves
                  the right, at your expense, to assume the exclusive defense
                  and control of any matter for which you are required to
                  indemnify us, and you agree to cooperate with our defense of
                  these claims.&nbsp; You agree not to settle any matter without
                  the prior written consent of the Company.&nbsp; The Company
                  will use reasonable efforts to notify you of any such claim,
                  action or proceeding upon becoming aware of it.
                </li>
                <li>
                  <u>Release</u>.&nbsp; You hereby release and forever discharge
                  the Company (and our officers, employees, agents, successors,
                  and assigns) from, and hereby waive and relinquish, each and
                  every past, present and future dispute, claim, controversy,
                  demand, right, obligation, liability, action and cause of
                  action of every kind and nature that has arisen or arises
                  directly or indirectly out of, or that relates directly or
                  indirectly to, the App (including any interactions with, or
                  act or omission of, other App users or any Third-Party Links
                  &amp; Ads).&nbsp; IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY
                  WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH
                  THE FOREGOING, WHICH STATES: &ldquo;A GENERAL RELEASE DOES NOT
                  EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT
                  TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
                  RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
                  AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.&rdquo;
                </li>
              </ol>
              <li>
                <strong>Limitation on Liability</strong>.
              </li>
              <ol>
                <li>
                  <u>Waiver of Consequential Damages</u>.&nbsp; TO THE MAXIMUM
                  EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE COMPANY (OR ITS
                  SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
                  PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE
                  PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                  INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR
                  RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE,
                  THE APP, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES.&nbsp; ACCESS TO, AND USE OF, THE
                  APP IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM,
                  OR LOSS OF DATA RESULTING THEREFROM.
                </li>
                <li>
                  <u>Cap on Liability</u>.&nbsp; TO THE MAXIMUM EXTENT PERMITTED
                  BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                  HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR
                  RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND
                  REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
                  LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S.&nbsp; $50). THE
                  EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS
                  LIMIT.&nbsp; YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO
                  LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
                  AGREEMENT.
                </li>
                <li>
                  <u>Exclusions</u>.&nbsp; SOME JURISDICTIONS DO NOT ALLOW THE
                  LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                  CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
                  MAY NOT APPLY TO YOU.
                </li>
              </ol>
              <li>
                <strong>Term and Termination</strong>.
              </li>
              <ol>
                <li>
                  <u>Term of Agreement</u>.&nbsp; Subject to this Section, these
                  Terms will remain in full force and effect while you use the
                  App.
                </li>
                <li>
                  <u>Suspension, Termination and the Effect of Termination</u>
                  .&nbsp; You may terminate your Account or a Subscription at
                  any time through the App.&nbsp; We may suspend or terminate
                  your Account, your Subscriptions or your rights to use the App
                  at any time and for any reason at our sole discretion,
                  including for any use of the App in violation of these
                  Terms.&nbsp; In the event we suspend access to your Account or
                  your Subscriptions, we will use commercially reasonable
                  efforts to notify you of the reason(s) for such suspension as
                  reasonably practicable prior to any suspension, but in no
                  event less than seventy-two (72) hours after the start of any
                  suspension.&nbsp; Upon termination of your rights under these
                  Terms for any reason other than our termination because of
                  your material breach, you will continue to be able to access
                  and use your Account, your Subscriptions and the App until the
                  end of the then current Subscription term, at which time your
                  Account, Subscriptions and right to access and use the App
                  will end.&nbsp; However, if we terminate your rights because
                  you violated these Terms, your Account, Subscriptions and
                  right to access and use the App will end immediately.&nbsp;
                  You understand that any termination of your Account may
                  involve deletion of your Materials associated with your
                  Account.&nbsp; The Company will not have any liability
                  whatsoever to you for any suspension or termination of your
                  rights under these Terms, including for termination of your
                  Account or Subscription or deletion of your Materials.&nbsp;
                  Even after your rights under these Terms are terminated, the
                  following provisions of these Terms will remain in effect:
                  Sections 1.2 through 1.5, Section 3 through 8, and Sections 11
                  through 18.
                </li>
              </ol>
              <li>
                <strong>Refunds</strong>.
              </li>
              <ol>
                <li>
                  <u>Termination of Subscriptions within 24-hours</u>.&nbsp; If
                  you purchase a Subscription and then cancel or terminate that
                  Subscription or your Account within twenty-four (24) hours
                  after you have purchased the Subscription (the &ldquo;
                  <strong>Grace Period</strong>&rdquo;), we will provide you a
                  refund of any pre-paid charges for the Subscription and any
                  registration costs you may have incurred (or your credit card
                  will not be charged if it had not been charged already).
                </li>
                <li>
                  <u>All Other Terminations</u>.&nbsp; In general, all purchases
                  and payments you make under these Terms are final and
                  non-refundable (except during the Grace Period set forth
                  above).&nbsp; If you terminate or cancel a Subscription or
                  your Account at any time after the Grace Period, or if we
                  terminate your rights under these Terms as a result of your
                  violation of these Terms, then you will not be entitled to a
                  refund of any remaining pre-paid charges you&rsquo;ve made to
                  us although you are able to access and use your Account,
                  Subscriptions and the App until the end of the then current
                  term, as set forth in Section 9.2 above.&nbsp; However,
                  notwithstanding the foregoing, we reserve the right to (but
                  are not obligated to) evaluate the circumstances of the
                  termination or cancellation of any Account or Subscription and
                  provide a full or partial refund based on our sole and
                  absolute discretion.
                </li>
              </ol>
              <li>
                <strong>Proprietary Information</strong>.&nbsp; The App, the
                content and information therein, and all improvements,
                additions, derivatives, and other modifications thereto, and any
                information pertaining to the foregoing, are the exclusive
                property of the Company and/or its licensors and shall be
                considered and treated by you as the proprietary information of
                the Company (the &ldquo;
                <strong>Company Proprietary Information</strong>&rdquo;).&nbsp;
                You acknowledge and agree that the Company is the owner of the
                Company Proprietary Information, and you agree that you have no
                right, title, or interest in any of the Company Proprietary
                Information except the right to use the App in accordance with
                and subject to this Agreement.&nbsp; You agree not to, directly
                or indirectly, disclose, sell, or otherwise transfer or exploit
                the Company Proprietary Information, or any portion thereof, to
                any other person or entity or allow any other person or entity
                to use the Company Proprietary Information, or any portion
                thereof, without the prior written consent of the Company, which
                may be withheld in the Company&rsquo;s sole discretion.&nbsp;
                You further agree not to challenge or assist with or participate
                in any challenge, directly or indirectly, of the Company&rsquo;s
                ownership of the Company Proprietary Information or any right,
                title, or interest therein or any portion thereof.
              </li>
              <li>
                <strong>Third-Party and Open-Source Components</strong>.&nbsp;
                We may include certain third-party software components and open
                source software components in or with the App that are subject
                to separate license agreements.&nbsp; To the extent you are
                required to agree separately to those separate license
                agreements or where a third-party license or an open source
                license expressly supersedes these Terms, that third-party
                license or open source license, as applicable, shall instead
                govern your use of the included third-party component or open
                source components.
              </li>
              <li>
                <strong>Connection Requirements</strong>.&nbsp; You are
                responsible for providing and maintaining, at your own risk,
                option, and expense, any hardware, software, and communication
                lines required to access and use the App, and we reserve the
                right to change the access configuration of the App at any time
                without prior notice.
              </li>
              <li>
                <strong>Responsibility for Equipment</strong>.&nbsp; Use of the
                App may require a computer, smartphone, or tablet.&nbsp; Unless
                otherwise agreed in writing between the parties, all equipment
                is provided solely by the users of the App.&nbsp; We have no
                responsibility for the operation or support, maintenance or
                repair of any equipment, software, Device or services that you
                elect to use in connection with the App.
              </li>
              <li>
                <strong>Third Party Carriers and Costs</strong>.&nbsp; The App
                communicates via internet and/or cellular data service provided
                by independent carriers.&nbsp; The internet or cellular data
                service provided by the independent carriers may fail or go
                off-line from time to time, and during any such outage the App
                will be unable to transmit and receive information.&nbsp; We may
                not receive timely notice of the communications outage from the
                independent carriers.&nbsp; We are not obligated to provide the
                App during any such outages.&nbsp; Cellular networks and
                internet providers may be regulated by federal and state
                agencies and changes in rules and regulations may require us to
                modify or terminate the App.&nbsp; You will be responsible for
                all data usage and other network usage fees charged by your
                cellular service carrier in connection with your use of the App.
              </li>
              <li>
                <strong>
                  Additional Terms for Users of the Apple and Google Mobile
                  Platforms
                </strong>
              </li>
              <ol>
                <li>
                  The following terms apply only to your use of the Apple and
                  Google Mobile Platforms (each a &ldquo;
                  <strong>Mobile Platform</strong>&rdquo;).&nbsp; In the event
                  of any conflict between this Section and the rest of the
                  Terms, this Section will control only for your use of the
                  respective Mobile Platform.
                </li>
                <li>
                  You must comply with all applicable third-party terms of
                  agreement when using the App.&nbsp; For example, your use of
                  the App must not violate the terms of your wireless data
                  services agreement for the applicable Device.
                </li>
                <li>
                  The Mobile Platform is a third-party beneficiary of these
                  Terms and may enforce these Terms against you as a third-party
                  beneficiary.&nbsp; Subject to the rights of the Mobile
                  Platform to enforce these Terms as a third-party beneficiary,
                  a person who is not a party to these Terms has no right under
                  any applicable law to enforce any term of these Terms.&nbsp;
                  Notwithstanding that any term of these Terms may be or become
                  enforceable by the Mobile Platform or any other third party,
                  these Terms or any of them may be varied, amended or modified
                  or these Terms may be suspended, canceled or terminated by
                  agreement in writing signed by or on behalf of the Company or
                  these Terms may be rescinded (in each case), without the
                  consent of the Mobile Platform or such other third party.
                </li>
                <li>
                  YOU HEREBY REPRESENT AND WARRANT THAT (I) YOU ARE NOT LOCATED
                  IN A COUNTRY THAT IS SUBJECT TO A U.S.&nbsp; GOVERNMENT
                  EMBARGO, OR THAT HAS BEEN DESIGNATED BY THE U.S.&nbsp;
                  GOVERNMENT AS A &ldquo;TERRORIST SUPPORTING&rdquo; COUNTRY;
                  AND (II) YOU ARE NOT LISTED ON ANY U.S.&nbsp; GOVERNMENT LIST
                  OF PROHIBITED OR RESTRICTED PARTIES.
                </li>
                <li>
                  YOU ACKNOWLEDGE THAT THE MOBILE PLATFORM HAS NO WARRANTY
                  OBLIGATION WHATSOEVER WITH RESPECT TO THE APPLICATION.
                </li>
                <li>
                  YOU ACKNOWLEDGE THAT TO THE EXTENT PERMITTED BY LAW, THE
                  MOBILE PLATFORM WILL NOT BE LIABLE TO USER FOR ANY CLAIMS,
                  LOSSES, LIABILITIES, DAMAGES, COSTS OR EXPENSES ATTRIBUTABLE
                  TO ANY FAILURE OF THE APPLICATION.
                </li>
                <li>
                  AS BETWEEN THE COMPANY AND THE MOBILE PLATFORM, BUT SUBJECT TO
                  THESE TERMS, THE MOBILE PLATFORM WILL NOT BE RESPONSIBLE TO
                  YOU FOR ANY CLAIM RELATING TO THE APPLICATION OR YOUR
                  POSSESSION AND/OR USE OF THE APPLICATION, INCLUDING BUT NOT
                  LIMITED TO, (I) PRODUCT LIABILITY CLAIMS; (II) ANY CLAIM THAT
                  THE APPLICATION FAILS TO CONFORM TO ANY APPLICABLE LEGAL OR
                  REGULATORY REQUIREMENT; AND (III) CLAIMS ARISING UNDER
                  CONSUMER PROTECTION OR SIMILAR LEGISLATION.
                </li>
              </ol>
              <li>
                <strong>Arbitration Agreement and Class Action Waiver</strong>.
              </li>
              <ol>
                <li>
                  <u>Dispute Resolution</u>.&nbsp; Please read this Arbitration
                  Agreement carefully.&nbsp; It is part of your contract with
                  the Company and affects your rights.&nbsp; It contains
                  procedures for MANDATORY BINDING ARBITRATION AND A CLASS
                  ACTION WAIVER.&nbsp; Arbitration is an alternative to
                  litigation where a neutral person (the arbitrator) hears and
                  decides the parties&rsquo; dispute.&nbsp; Arbitration
                  proceedings are designed to provide parties with a fair
                  hearing in a manner that is faster and less formal than court
                  proceedings.&nbsp; The following procedures apply to all
                  arbitration proceedings involving you and us.
                </li>
                <li>
                  <u>Applicability of Arbitration Agreement</u>.&nbsp; All
                  claims and disputes (excluding claims for injunctive or other
                  equitable relief as set forth below) in connection with the
                  Terms or the use of any product or service provided by the
                  Company that cannot be resolved informally or in small claims
                  court shall be resolved by binding arbitration on an
                  individual basis under the terms of this Arbitration
                  Agreement.&nbsp; Unless otherwise agreed to, all arbitration
                  proceedings shall be held in English.&nbsp; This Arbitration
                  Agreement applies to you and the Company, and to any
                  subsidiaries, affiliates, agents, employees, predecessors in
                  interest, successors, and assigns, as well as all authorized
                  or unauthorized users or beneficiaries of services or goods
                  provided under the Terms.
                </li>
                <li>
                  <u>Notice Requirement and Informal Dispute Resolution</u>
                  <em>.</em>&nbsp; Before either party may seek arbitration, the
                  party must first send to the other party a written Notice of
                  Dispute (&ldquo;<strong>Notice</strong>&rdquo;) describing the
                  nature and basis of the claim or dispute, and the requested
                  relief.&nbsp; A Notice to the Company should be sent to: 75
                  5th St NW, Ste 2210, Atlanta, GA 30308.&nbsp; After the Notice
                  is received, you and the Company may attempt to resolve the
                  claim or dispute informally.&nbsp; If you and the Company do
                  not resolve the claim or dispute within thirty (30) days after
                  the Notice is received, either party may begin an arbitration
                  proceeding.&nbsp; The amount of any settlement offer made by
                  any party may not be disclosed to the arbitrator until after
                  the arbitrator has determined the amount of the award, if any,
                  to which either party is entitled.
                </li>
                <li>
                  <u>Rules for Arbitration.&nbsp; </u>Any dispute, claim or
                  controversy arising out of or relating to these Terms or the
                  breach, termination, enforcement, interpretation or validity
                  thereof, including the determination of the scope or
                  applicability of this agreement to arbitrate, shall be
                  determined by arbitration before one arbitrator in Atlanta,
                  Georgia, unless the parties agree to a different
                  location.&nbsp; The arbitration shall be administered pursuant
                  to JAMS's Streamlined Arbitration Rules and Procedures
                  (&ldquo;<strong>JAMS Rules</strong>&rdquo;).&nbsp; The
                  arbitrator shall have exclusive authority to resolve any
                  arbitrability issues including any dispute over these Terms or
                  this arbitration provision&rsquo;s scope, application,
                  meaning, and enforceability.&nbsp; The arbitrator shall be
                  empowered to grant whatever relief would be available in
                  court.&nbsp; Any award of the arbitrator(s) shall be final and
                  binding and may be entered as a judgment in any court having
                  jurisdiction.&nbsp; This clause shall not preclude parties
                  from seeking provisional remedies in aid of arbitration from a
                  court of appropriate jurisdiction.&nbsp; The one exception to
                  the exclusivity of arbitration is that either party has the
                  right to bring an individual claim against the other in a
                  small claims court of competent jurisdiction, or, if filed in
                  arbitration, the responding party may request that the dispute
                  proceed in small claims court instead if the claim is within
                  the jurisdiction of the small claims court.&nbsp; If the
                  request to proceed in small claims court is made before an
                  arbitrator has been appointed, any open arbitration between
                  the parties shall be administratively closed.&nbsp; If an
                  arbitrator has been appointed, the arbitrator shall determine
                  whether the dispute should remain in arbitration or instead be
                  decided in small claims court.&nbsp; Arbitration shall be
                  conducted by written submissions only, unless either you or
                  the Company invoke the right to an oral hearing before the
                  arbitrator.
                </li>
                <li>
                  <u>Time Limits</u>.&nbsp; If you or the Company pursue
                  arbitration, the arbitration action must be initiated and/or
                  demanded within the statute of limitations (i.e., the legal
                  deadline for filing a claim) and within any deadline imposed
                  under the JAMS Rules for the pertinent claim.
                </li>
                <li>
                  <u>Waiver of Jury Trial</u>.&nbsp; You and the Company
                  expressly and knowingly WAIVE THE RIGHT TO TRIAL BY
                  JURY.&nbsp; This means that if for any reason the Arbitration
                  Agreement contained in Section 17 is not enforced or is found
                  inapplicable, or either of us file the action in small claims
                  court, our claims against each other will be resolved by a
                  judge rather than a jury.
                </li>
                <li>
                  <u>Waiver of Class or Consolidated Actions</u>.&nbsp; ALL
                  CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
                  AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL
                  BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE
                  CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                  CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
                </li>
                <li>
                  <u>Confidentiality</u>.&nbsp; All aspects of the arbitration
                  proceeding, including but not limited to the award of the
                  arbitrator and compliance therewith, shall be strictly
                  confidential.&nbsp; The parties agree to maintain
                  confidentiality unless otherwise required by law.&nbsp; This
                  paragraph shall not prevent a party from submitting to a court
                  of law any information necessary to enforce this Agreement, to
                  enforce an arbitration award, or to seek injunctive or
                  equitable relief.
                </li>
              </ol>
              <li>
                <strong>General</strong>.
              </li>
              <ol>
                <li>
                  <u>Export</u>.&nbsp; The App may be subject to U.S. export
                  control laws and may be subject to export or import
                  regulations in other countries.&nbsp; You agree not to export,
                  reexport, or transfer, directly or indirectly, any U.S.
                  technical data acquired from the Company, or any products
                  utilizing such data, in violation of the United States export
                  laws or regulations.
                </li>
                <li>
                  <u>Disclosures</u>.&nbsp; The Company is located at the
                  address in Section 18.6.&nbsp; If you are a California
                  resident, you may report complaints to the Complaint
                  Assistance Unit of the Division of Consumer Product of the
                  California Department of Consumer Affairs by contacting them
                  in writing at 400 R Street, Sacramento, CA 95814, or by
                  telephone at (800) 952-5210.
                </li>
                <li>
                  <u>Electronic Communications</u>.&nbsp; The communications
                  between you and the Company use electronic means, whether you
                  use the App or send us emails, or whether the Company posts
                  notices on the App or communicates with you via email.&nbsp;
                  For contractual purposes, you (a) consent to receive
                  communications from the Company in an electronic form; and (b)
                  agree that all terms and conditions, agreements, notices,
                  disclosures, and other communications that the Company
                  provides to you electronically satisfy any legal requirement
                  that such communications be in a hardcopy writing.&nbsp; The
                  foregoing does not affect your non-waivable rights.
                </li>
                <li>
                  <u>Entire Terms</u>.&nbsp; These Terms constitute the entire
                  agreement between you and us regarding the use of the
                  App.&nbsp; Our failure to exercise or enforce any right or
                  provision of these Terms shall not operate as a waiver of such
                  right or provision.&nbsp; The section titles in these Terms
                  are for convenience only and have no legal or contractual
                  effect.&nbsp; The word &ldquo;including&rdquo; means
                  &ldquo;including without limitation&rdquo;.&nbsp; If any
                  provision of these Terms is, for any reason, held to be
                  invalid or unenforceable, the other provisions of these Terms
                  will be unimpaired and the invalid or unenforceable provision
                  will be deemed modified so that it is valid and enforceable to
                  the maximum extent permitted by law.&nbsp; Your relationship
                  to the Company is that of an independent contractor, and
                  neither party is an agent or partner of the other.&nbsp; These
                  Terms, and your rights and obligations herein, may not be
                  assigned, subcontracted, delegated, or otherwise transferred
                  by you without the Company&rsquo;s prior written consent, and
                  any attempted assignment, subcontract, delegation, or transfer
                  in violation of the foregoing will be null and void.&nbsp; The
                  Company may freely assign these Terms.&nbsp; The terms and
                  conditions set forth in these Terms shall be binding upon
                  assignees.
                </li>
                <li>
                  <u>Copyright/Trademark Information</u>.&nbsp; Copyright &copy;
                  2022 MaxRewards, Inc.&nbsp; All rights reserved.&nbsp; All
                  trademarks, logos and service marks (&ldquo;
                  <strong>Marks</strong>&rdquo;) displayed on the App are our
                  property or the property of other third parties.&nbsp; You are
                  not permitted to use these Marks without our prior written
                  consent or the consent of such third party which may own the
                  Marks.
                </li>
                <li>
                  <u>Contact Information</u>: 75 5th St NW, Ste 2210, Atlanta,
                  GA 30308. Email: support@maxrewards.com
                </li>
              </ol>
            </ol>
            <p>Last revised on: Sept 21, 2022</p>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default TermsOfUsePage
